import React from "react";
import logo from "../../assets/images/logo.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "./navbar.css";
import { useState, useEffect } from "react";
export default function Navbar() {
  const background = "bg-dark-primary";
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [openSubAbout, setOpenSubAbout] = useState<boolean>(false);
  const [openSubInitiative, setOpenSubInitiative] = useState<boolean>(false);
  const [navbarBg, setNavbarBg] = useState<string>(background);

 
  return (
    <>
      <section>
        <nav
          className={`fixed ${navbarBg} text-white uppercase w-full transition-colors z-30 `}
        >
          <div
            id="tablet"
            className="hidden tablet:flex flex-row justify-between items-center gap-8 w-full py-4 px-8 "
          >
            <div className="flex flex-row justify-center items-center ">
              <a href="/">
                <img src={logo} alt="" className="scale-75" />
              </a>
            </div>
            <div className="w-full">
              <ul className="flex flex-row justify-end items-center gap-3 laptop:gap-8 text-sm desktop-xl:text-xl">
                <li className="group">
                  <a
                    href="/"
                    className="hover:border-b-2  group-hover:border-b-2 py-1"
                  >
                    about
                  </a>
                  <div className="hidden group-hover:flex absolute w-44">
                    <div className="bg-dark-primary mt-4  w-full text-start">
                      <ul>
                        <li className="p-4 hover:bg-orange-primary transition-colors">
                          <a href="/#about">who we are</a>
                        </li>
                        <li className="p-4 hover:bg-orange-primary transition-colors">
                          <a href="/#vision">our vision</a>
                        </li>
                        <li className="p-4 hover:bg-orange-primary transition-colors">
                          <a href="/#team">our team</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li className="group">
                  <a
                    href="/initiative"
                    className="hover:border-b-2  group-hover:border-b-2 py-1"
                  >
                    initiatives
                  </a>
                  <div className="hidden group-hover:flex absolute   w-60">
                    <div className="bg-dark-primary mt-4  w-full text-start">
                      <ul>
                        <li className="p-4 hover:bg-orange-primary transition-colors">
                          <a href="/initiative#net-zero">net-zero</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li>
                  <a
                    href="https://www.globaltechadvocates.org/community/"
                    className=""
                  >
                    community
                  </a>
                </li>

                <li className="text-center bg-orange-primary p-2">
                  <a href="/become-and-advocate">become an advocate</a>
                </li>
                <li>
                  <a
                    href="https://techsauce.co/en/techthailandadvocates"
                    className=""
                  >
                    news
                  </a>
                </li>
                <li>
                  <a href="/contact" className="">
                    contact
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </section>
      <section
        className={`fixed bg-tranparent text-white uppercase w-full  z-30 `}
      >
        <nav>
          <div
            id="mobile"
            className="tablet:hidden bg-black flex flex-row justify-between items-center gap-8 w-full py-4  uppercase"
          >
            <div className="flex flex-row justify-start items-start">
              <a href="/" className="flex flex-row justify-start items-start">
                <img src={logo} alt="" className="scale-[0.6]" />
              </a>
            </div>
            <div className="flex flex-row justify-center items-center">
              <button
                type="button"
                aria-label="toggle menu"
                aria-expanded="true"
                onClick={() => {
                  setOpenMenu(!openMenu);
                }}
              >
                <div className="flex flex-row justify-center items-center w-[40px] h-[40px]">
                  <svg
                    viewBox="0 0 100 45"
                    width="40"
                    height="40"
                    className="fill-orange-primary "
                  >
                    <rect width="60" height="10"></rect>
                    <rect y="20" width="60" height="10"></rect>
                    <rect y="40" width="60" height="10"></rect>
                  </svg>
                </div>
              </button>
            </div>
          </div>
        </nav>
        <div
          className={`tablet:hidden origin-top animate-open-menu ${
            openMenu ? "block" : "hidden"
          }  bg-black/70 h-[100vh] overflow-hidden`}
          >
          <div className={` bg-white text-dark-primary `}>
            <ul className="text-start  border-b">
              <li className="py-2 border-b ">
                <div className="flex flex-row justify-between">
                  <a href="/" className="pl-8" onClick={()=>setOpenMenu(false)}>
                    about
                  </a>
                  <button
                    type="button"
                    onClick={() => setOpenSubAbout(!openSubAbout)}
                    className="pr-8"
                  >
                    <KeyboardArrowDownIcon
                      className={`${
                        openSubAbout ? "rotate-180" : ""
                      } transition-transform duration-200 delay-300`}
                    />
                  </button>
                </div>
                <div
                  className={`${
                    openSubAbout ? "block" : "hidden"
                  }  origin-top duration-200 delay-300`}
                >
                  <ul>
                    <li className="py-2  pl-14">
                      <a href="/#about" onClick={()=>setOpenMenu(false)}>who we are</a>
                    </li>
                    <li className="py-2  pl-14">
                      <a href="/#vision" onClick={()=>setOpenMenu(false)}>our vision</a>
                    </li>
                    <li className="py-2 pl-14">
                      <a href="/#team" onClick={()=>setOpenMenu(false)}>our team</a>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="py-2 border-b">
              <div className="flex flex-row justify-between">
                  <a href="/initiative" className="pl-8" onClick={()=>setOpenMenu(false)}>
                    initiative
                  </a>
                  <button
                    type="button"
                    onClick={() => setOpenSubInitiative(!openSubInitiative)}
                    className="pr-8"
                  >
                    <KeyboardArrowDownIcon
                      className={`${
                        openSubInitiative ? "rotate-180" : ""
                      } transition-transform duration-200 delay-300`}
                    />
                  </button>
                </div>
                <div
                  className={`${
                    openSubInitiative ? "block" : "hidden"
                  }  origin-top duration-200 delay-300`}
                >
                  <ul>
                    <li className="py-2  pl-14">
                      <a href="/initiative#net-zero" onClick={()=>setOpenMenu(false)}>net-zero</a>
                    </li>
                   
                  </ul>
                </div>
              </li>
              <li className="py-2 border-b">
                <a href="https://www.globaltechadvocates.org/community/" className="pl-8" onClick={()=>setOpenMenu(false)}>
                  community
                </a>
              </li>
              <li className="py-2 border-b">
                <a href="/become-and-advocate" className="pl-8" onClick={()=>setOpenMenu(false)}>
                  become an advocate
                </a>
              </li>
              <li className="py-2 ">
                <a href="/contact" className="pl-8" onClick={()=>setOpenMenu(false)}>
                  contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}
